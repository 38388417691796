import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeBannerImage from '../assets/images/home/landing-row-bg.png';
import { Link } from 'gatsby';
import '../assets/css/styles.css';
import { launchVideos } from '../data';
import { lng, setLang } from '../components/essentials';
import playButton from '../assets/images/icons/play-2.svg'
import LivePage from './live';

const LivePageArabic = props => LivePage({ ...props, lang: 'ar' });

export default LivePageArabic;